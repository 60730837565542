*[data-state] {
  --gray-safe: #{darken($gray-safe, 7)};
  background-color: var(--comp-background-color);

  .state-icon {
    @extend %iconic;
  }

}

*[data-state="info"] {
  @extend %info-outline;

  .state-icon::before {
    content: '\f05a';
  }
}

*[data-state="warning"] {
  @extend %warning-outline;

  .state-icon::before {
    content: '\f071';
  }
}

*[data-state="danger"] {
  @extend %danger-outline;

  .state-icon::before {
    content: '\f06a';
  }
}

*[data-state="success"] {
  @extend %success-outline;

  .state-icon::before {
    content: '\f058';
  }
}

*[data-icon="date"] {
  .state-icon::before {
    content: '\f073';
  }
}

.card__link {
  &[data-state="primary"] {
    @extend %info-solid;
  }

  &[data-state="danger"] {
    @extend %danger-outline;
    background-color: $clear;
    transition: all .25s ease;

    &:hover {
      background-color: var(--danger-light);
      color:  var(--danger-solid);
    }
  }
}