.wrapper {
	max-width: 1168px;
  // margin: 0 auto;
  // padding-left: $el-pad;
  // padding-right: $el-pad;

  margin-inline: auto;
  padding-inline: $el-pad;

  width: calc((100%) - (30px));

  &.full-site {
    max-width: $screen-lg + $grid-gutter-width;
    padding-left: 0;
    padding-right: 0;
  }
  &.no-pad {
    padding-left: 0;
    padding-right: 0;
  }
}