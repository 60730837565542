.form-control {
  color: $black;
}

.form-group {
  margin-top: beat(4);

  &:first-child {
    margin-top: beat(2);
  }

  &.horizontal {
    align-items: baseline;
  }

  .field-flex {
    @include labler(5);

    display: flex;
    align-items: flex-start;

    label {
      line-height: 2.5;
      padding-right: 0.75rem;
    }
  }

  .form-control {
    box-sizing: border-box;
  }

  &[data-mask='date'] input {
    max-width: 8.5rem;
  }

  &[data-mask='ssn-full'] input {
    max-width: 8.5rem;
  }

  &[data-mask='ssn-four'] input {
    max-width: 5.5rem;
  }

  &[data-mask='pct'] .input-group {
    max-width: 7.25rem;

    input {
      text-align: right;
    }
  }
}

.help-text {
  color: var(--gray-safe);
  display: block;
  padding-top: beat(1);
}

.cbr-combo {
  display: flex;
  width: fit-content;
  height: 38px;

  .form-control {
    border-radius: $border-radius-base 0 0 $border-radius-base;
  }

  .checkbox-attach {
    background-color: var(--comp-backgound-color);
    border: 1px solid $input-border;
    border-left: 0;
    border-radius: 0 $border-radius-base $border-radius-base 0;
    padding-left: $el-pad;
    padding-right: $table-sides;
  }

  label {
    span {
      display: none;
      font-weight: 400;
    }
  }

  input:checked + label {
    span {
      display: inline;
    }
  }
}

.has-error {
  .cbr-combo {
    .checkbox-attach {
      @extend %danger-outline;

      border-color: var(--comp-border-color);
    }
  }
}

.field-line {
  display: flex;
  flex-wrap: wrap;
  gap: beat(2) 2rem;
  margin-top: beat(4);

  .form-group {
    margin-bottom: 0;
    margin-top: 0;

    & > div {
      display: flex;

      label {
        display: block;
        line-height: 2.25;
        padding-right: .75rem;
      }
    }
  }

  &.dates {
    label {
      flex: 0 0 5.25rem;
    }
  }
}

@include responsive(xs) {
  .field-line .form-group > div label {
    line-height: 2.5;
  }
}


.req-note {
  font: var(--small-type);
  font-style: italic;
  margin-bottom: beat(4);
}

.req-label .req {
  font: var(--small-type);
  color: var(--gray-safe);
  font-style: italic;
  margin-left: .5em;
}

.field-flex .req-label .req {
  display: block;
  margin-left: 0.75em;
  margin-top: -0.75em;
}

.req-label::before {
  content: '* ';

  // margin-left: -.75rem;
}

// .head-legend {
//   font: var(--x-large-type);

//   @include underliner;

//   font-weight: 400;
// }

// h2.head-legend,
legend {
  font: var(--large-type);

  @include underliner;

  line-height: 1.35;
  padding-top: 0;
  margin-bottom: beat(2);

  & + .form-group,
  & + .field-line,
  & + * {
    margin-top: beat(2);
  }
}

// fieldset + fieldset > h2.head-legend,
fieldset + fieldset > legend {
  padding-top: beat(8);
}

select.form-control {
  min-height: 38px;
  width: auto;
}

.text-danger {
  @extend %danger-text;
}

.inline_error {
  font: var(--small-type);

  @extend %danger-text;

  margin: beat(1) 0 0;
}

.checkbox {
  align-items: baseline;
  display: flex;
  margin: beat(2) 0;

  &:has(.help-text) {
    flex-wrap: wrap;

    .help-text {
      flex-basis: 100%;
      padding-left: 1.25rem;
    }
  }

  &:has(:checked) {
    .help-text {
      color: var(--body-text);
    }
  }

  input[type="checkbox"] {
    position: relative;
    margin: 0;
  }

  input + label,
  input + .control-label {
    padding-left: 0.37em;
  }

  input:checked + label,
  input:checked + .control-label {
    font-weight: 500;
    color: $black;
  }
}

.cb-inline {
  display: flex;
  align-items: center;
  margin-bottom: beat(4);
  padding: beat(1) 0;

  & > * {
    margin: 0 !important;
    padding-right: .75rem;

    &:last-child {
      padding-right: 0;
    }
  }
}

.text-count-status {
  font: var(--small-type);
  color: var(--gray-safe);
  margin: 0;
  max-width: 100% !important;
  padding: beat(1) $table-sides;

  &.alert-warning,
  &.alert-danger {
    color: $black;
  }

  .maxed {
    font-weight: 700;
  }
}

.h5-date input {
  max-width: 12rem;
}

.h5-tel + .ext {
  margin-top: beat(2);
}

.small-num,
.soft-date {
  width: 7.75rem;
}

.pw-reveal {
  // position: relative;
  display: flex;
  flex-wrap: wrap;

  .form-control {
    flex: 1;

    // border-radius: $border-radius-base 0 0 $border-radius-base;

    & ~ * {
      flex: 0 0 100%;
    }
  }

  ::-ms-reveal {
    display: none;
  }

  .toggle-password {
    border: 1px solid #ccc;
    border-radius: 0 $border-radius-base $border-radius-base 0;
    margin-left: $border-radius-base * -1;
    flex: 0 0 4rem;
    padding: 3px $table-sides;
    visibility: hidden;
  }

  &:focus-within .toggle-password {
    visibility: visible;
  }
}

.success-page {
  &:has(.alert:first-child) {
    padding-top: beat(6);
  }
}

.transaction-summary {
  @extend %component;

  border: 0;
  border-radius: 0;
  margin-bottom: beat(2);
  padding: beat(1) 0;

  h2 {
    font: 500 var(--base);
  }

  dl {
    align-items: baseline;
    display: flex;
    flex-wrap: wrap;
    padding: 0 $table-sides;
  }

  dt {
    @extend %data-label;

    color: var(--gray-safe-comp);
    padding-top: beat(0.5);
  }

  dd {
    padding: 0 1.25em 0 0.5em;
  }
}

@include responsive(xxs) {
  .transaction-summary {
    dl {
      flex-direction: column;
    }

    dd + dt {
      margin-top: beat(2);
    }
  }
}


.transaction-head {
  @include underliner;

  align-items: center;
  border-bottom-color: var(--grayscale-solid);
  display: flex;
  justify-content: space-between;
  padding-inline: $table-sides;
}