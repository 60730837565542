

// Process Map
$first-cover: linear-gradient(to right, rgba(249, 249, 249, 1) 0%, rgba(249, 249, 249, 1) 50%, rgba(249, 249, 249, 0) 51%, rgba(249, 249, 249, 0) 100%);
$last-cover: linear-gradient(to right, rgba(249, 249, 249, 0) 0%, rgba(249, 249, 249, 0) 50%, rgba(249, 249, 249, 1) 45%, rgba(249, 249, 249, 1) 100%);
$step-todo: url('/lmpublic/img/prc_map_todo.png');
$step-done: url('/lmpublic/img/prc_map_done.png');

#process-overview {
  background-color: var(--comp-background-color);
  order: -10;
  h3 {
  font: var(--large-type);
    font-weight: 500;
    text-align: center;
    margin: 0;
    padding-top: beat(6);
  }
}

.process-map {
  margin: 0 auto; 
  max-width: 1230px;

  ol {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    flex: 0 1 auto;
    padding: beat(2) $grid-gutter-width;
    padding: beat(2) $el-pad;
    text-align: center;
    background-repeat: repeat-x;
    background-image: $step-todo;
    margin-right: 0;
    white-space: nowrap;

    &:first-child {
      background-image: $first-cover, $step-todo;
      padding-left: $el-pad;
    }

    &:last-child {
      background-image: $last-cover, $step-todo;
      padding-right: $el-pad;
    }

    &:after {
      color: #ccc;
      content: '\f111';
      display: block;
      font-family: 'FontAwesome';
      font-weight: 700;
      font-size: .75rem;
      line-height: 12px;
      padding: beat(2) 0 beat(1);
    }

    &.active {
      background-image: url("/lmpublic/img/prc_map_base.png");
      background-position: 50% 0;

      &:after {
        color: $black;
        content: '\f124';
        font-size: 1rem;
        transform: rotate(-45deg);

        // margin-top: 5px;
     
      }

      &:first-child {
        background-image: $first-cover, $step-todo;
      }

      &:last-child {
        background-image: $last-cover, $step-done;
      }
    }

    &.completed {
      background-image: $step-done;

      &:after {
        color: $black;
      }

      &:first-child {
        background-image: $first-cover, $step-done;
      }

      &:last-child {
        background-image: $last-cover, $step-done;
      }
    }
  }

  &.checklist {
    li {
      &:after {
        font-size: 1.5rem;
      }

      &.completed {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:after {
          content: '\f058';
          color: $brand-success;
          background-color: var(--comp-background-color);
        }
      }
    }
  }
}

.page-header .sub-head.proc-count {
  display: none;
}

.process-label {
  background-color: var(--comp-background-color);
  margin-bottom: beat(4);
  padding: beat(2) $el-pad beat(2);
  text-align: center;

  h1 {
  font: var(--large-type);

  

    font-weight: normal;
    padding: 0;
    margin: 0;
  }
}


@include responsive(xs){
  .process-map {
    display: none;
  }

  .page-header .sub-head.proc-count {
    display: block;
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
  }

};