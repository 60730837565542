/*  4. Custom Mixins  */
// Responsive breakpoint generation

@mixin responsive ($width) {
    // Only 1200px and larger
    @if $width == lg {
        @media only screen and (min-width: $screen-lg)
        {@content}
    }
    // 1199px and less
    @else if $width == md {
        @media only screen and (max-width: $screen-md-max)
        {@content}
    }
    // 992px and up
    @else if $width == md-up {
        @media only screen and (min-width: $screen-md)
        {@content}
    }
    // Only between 992px and 1199px
    @else if $width == md-only {
        @media only screen and (min-width: $screen-md) and (max-width: $screen-md-max)
        {@content}
    }
    // 991px and less
    @else if $width == sm {
        @media only screen and (max-width: $screen-sm-max)
        {@content}
    }
    // 768px and up
    @else if $width == sm-up {
        @media only screen and (min-width: $screen-sm)
        {@content}
    }
    // Only between 768px and 991px
    @else if $width == sm-only {
        @media only screen and (min-width: $screen-sm) and (max-width: $screen-sm-max)
        {@content}
    }
    // 767px and less
    @else if $width == xs {
        @media only screen and (max-width: $screen-xs-max)
        {@content}
    }
    // 480px and up
    @else if $width == xs-up {
        @media only screen and (min-width: $screen-xs)
        {@content}
    }
    // Only between 480px and 767px
    @else if $width == xs-only {
        @media only screen and (min-width: $screen-xs) and (max-width: $screen-xs-max)
        {@content}
    }
    // 379px and less
    @else if $width == xxs {
        @media only screen and (max-width: $screen-xs - 1)
        {@content}
    }
}
@mixin print-screen () {
    @media only print
    {@content}
}


@function map-deep-get($map, $keys...) {
  // @warn $map;
  // @warn $keys;
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@function tint($color, $percentage) {
  @return mix($white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix($black, $color, $percentage);
}

@function set-text-color($color) {
  @if lightness($color) > 50 {
    @return $black; // Lighter backgorund, return dark color
  } @else {
    @return $white; // Darker background, return light color
  }
}

@function set-color-map($base) {
  $colorMap: ();
  $setMap: ();

  @for $i from 10 through 1 {
    $keyVal: $i * 9.5; // Alters color tint distribution
    $keyValName: $i * 10;
    $setMap: (l-#{$keyValName}: tint($base, $keyVal));
    $colorMap: map-merge($colorMap, $setMap);
  }

  $baseMap: (base: $base);
  $colorMap: map-merge($colorMap, $baseMap);

  @for $i from 1 through 10 {
    $keyVal: $i * 7; // Alters color shade distribution
    $keyValName: $i * 10;
    $setMap: (d-#{$keyValName}: shade($base, $keyVal));
    $colorMap: map-merge($colorMap, $setMap);
  }

  @return $colorMap;
}

@mixin comp-colors-solid($state--color) {
  $my-map: map-get($palette, $state--color);
  $my-var: --#{$state--color};

  --comp-key-color: #{map-get($my-map, 'base')};
  --comp-background-color: #{map-get($my-map, 'd-20')};
  --comp-border-color: #{map-get($my-map, 'd-20')};
  --comp-text-color: #{set-text-color(map-get($my-map, 'd-20'))};
}

@mixin comp-colors-shade($state--color) {
  $my-map: map-get($palette, $state--color);
  $my-var: --#{$state--color};

  --comp-key-color: #{map-get($my-map, 'base')};
  --comp-background-color: #{map-get($my-map, 'l-80')};
  --comp-border-color: #{map-get($my-map, 'l-60')};
  --comp-text-color: #{map-get($my-map, 'd-70')};
}

@mixin comp-colors-outline($state--color) {
  $my-map: map-get($palette, $state--color);
  $my-var: --#{$state--color};

  --comp-key-color: #{map-get($my-map, 'base')};
  --comp-background-color: #{map-get($my-map, 'l-100')};
  --comp-border-color: #{map-get($my-map, 'l-80')};
  --comp-text-color: #{map-get($my-map, 'd-50')};
}



@function beat($beats: 4) {
  @return $line-height / 4 * $beats * 1rem;
}

@mixin underliner($pad: 1, $mar: 0, $col: var(--comp-border-color)) {
  border-bottom: 1px solid $col;
  padding-bottom: calc(#{beat($pad)} - 1px);

  @if $mar != 0 {
    margin-bottom: beat($mar);  
  }
}

// Set FontAwesome
@mixin set-fa($fa-char: '024', $placement: 'before', $weight: 700) {


  &::#{$placement} {
    font-family: 'FontAwesome';
    content: #{'"\\f' + $fa-char + '"'};
    margin-right: 0.35em;
    font-weight: $weight;

    @if $placement == 'after' {
      margin-left: .5rem;
      margin-right: 0;
    }

    @if $fa-char == '024' {
      color: red;
    }
  }
}


@mixin mlGradient($topColor: $gradTop, $bottomColor: $gradBottom) {
    @include background-image(linear-gradient($topColor, $bottomColor));
};


@mixin card-shadow() {
    box-shadow: 0 1px 2px #aaa;  // Google Cards
}

//  For development only
@mixin shaded($color: 156, $opacity: 0.5) {
  background-color: rgba($color, $color, $color, $opacity);
};

@mixin bgOverlay($brightness,$amount) {
    background-image: url("../../img/bg_" + $brightness + $amount + ".png");
}


//
// map-fetch($map, $keys)
//
// An easy way to fetch a deep value in a multi-level map. Works much like
// map-get() except that you pass multiple keys as the second parameter to
// go down multiple levels in the nested map.
//
// Credit: J W Long
// URL:    https://gist.github.com/jlong/8760275
//

@function map-fetch($map, $keys) {
  $key: nth($keys, 1);
  $length: length($keys);
  $value: map-get($map, $key);
  @if ($length > 1) {
    $rest: ();
    @for $i from 2 through $length {
      $rest: append($rest, nth($keys, $i))
    }
    @return map-fetch($value, $rest)
  } @else {
    @return $value;
  }
}


//
//  Bootstrap Adjustments
//

@mixin strap-stretch() {
    margin-inline: $el-pad * -1;
    padding-inline: $el-pad;

}

@mixin adjust-multicol($label-width, $measure-unit, $data-width: null) {
    @include responsive(xs){
        @if $measure-unit == "em" {
            $label-width: 1em * $label-width;
            td[class*="col-"] {
                @include adjust-font-size-to($nrw-large-type,4);
                margin-left: $label-width + 1em;
                @if $data-width != null {
                    width: $data-width * 1em;
                }
            }
        }
        @else if $measure-unit == "pct" {
            $label-width: 1% * $label-width;
            td[class*="col-"] {
                margin-left: $label-width + 4%;
            }
        }
        @else if $measure-unit == "px" {
            $label-width: 1px * $label-width;
            td[class*="col-"] {
                margin-left: $label-width + $el-pad;
            }
        }
        td[class*="col-"]:first-child {
            width: auto;
            margin-left: $el-pad * -1;
        }
        div.micro-th {
            width: $label-width;
        }
    };
}

@mixin labler($wid) {
    --label-length: #{($wid * 1rem)};

    & > *:nth-child(odd){
      flex: 0 0 var(--label-length);
      box-sizing: border-box;
    }

    & > *:nth-child(even) {
      // flex: 0 0 calc((100%) - (#{($wid * 1rem)}) - (#{($table-sides * 3)}));
      flex: 0 0 calc((100%) - (var(--label-length)));
      box-sizing: border-box;
    }

}
