.global-nav {
  padding-top: beat(6);
  background-color: #fff;

  a {
    text-decoration: none;
  }

  .top-level {
    padding-right: $el-pad;
  }

  .top-level > li > a {
    font: var(--large-type);
  }
}

.pre-nav {
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: beat(1.5);
}

.main-nav {
  background-color: $gradTop;
  background-image: linear-gradient($gradTop, $gradBottom);
}

.navbar-default .navbar-brand {
  display: block;
  overflow: hidden;
  height: 50px;
  flex: 0 0 350px;
  padding: 0;

  img {
    margin-bottom: 10px;
  }
}

.skippy {
  position: absolute;
  top: 0;
  padding: 0;
  left: 50%;
  transform: translateX(-50%);

  a {
    @include set-fa("063", "after");

    background-color: var(--grayscale-lighter);
    color: $link-color;
    display: inline-block;
    font-weight: 500;
    padding: beat(1) $el-pad;
  }
}

.acct-controls {
  font: var(--small-type);
  padding: 0;

  .nav {
    display: flex;
    justify-content: flex-end;
  }

  .nav > li > a {
    padding: beat(1) 10px;
    color: $navbar-default-link-color;
    border-radius: $border-radius-small;
    white-space: nowrap;

    &:hover {}
  }
}

.nav {
  .msgctr {
    position: relative;

    .badge {
      position: absolute;
      top: -3px;
      left: 4px;
      font-size: 0.83333333em;
      line-height: 1.35em;
      font-weight: 400;
      padding: 1px 4px;
      background-color: #ae2619;
    }
  }
}

.dropdown-toggle::before {
  content: '';
  border: 6px solid $clear;
  border-left-color: $black;
  border-right-width: 0;
  display: inline-block;
  margin-right: 3px;
}

.open .dropdown-toggle::before {
  transform: rotate(90deg);
}

.dropdown-menu {
  & > .active {
    a,
    a:hover,
    a:focus {
      background-color: var(--grayscale-lighter);
    }
  }
}

@include responsive(sm-up) {
  .navbar-default .navbar-brand {
    img {
      filter: var(--svg-primary);
    }
  }
}


@include responsive(sm-only) {
  .navbar-default .navbar-brand {
    border-bottom: 5px solid $white;
    flex: 0 0 315px;
    height: 45px;

    // margin-top: -.5rem;
    // padding-top: beat(.5);
    // background-color: pink;

    img {
      width: 100%;
    }
  }
}


.navbar-default .navbar-toggle {
  font: var(--large-type);
  padding: 0;
  margin: beat(1) 0 0;
}

@include responsive(xs) {
  .global-nav {
    background-color: var(--primary-text);
    padding: 0;

    .top-level {
      padding-right: 0;
      margin-bottom: 0;
    }

    .dropdown-toggle::before {
      border-left-color: $white;
    }
  }

  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    border-color: $clear;
  }

  .navbar-default {
    .navbar-nav {
      & > li {
        & > a {
          border-left: 0.5rem solid $clear;
          color: var(--grayscale-light);
          padding-left: $grid-gutter-width;

          &:focus,
          &:hover {
            background-color: var(--inverse-bg);
            color: var(--grayscale-light);
          }

          &.dropdown-toggle {
            padding-left: $el-pad;
          }
        }

        &.active a {
          // background-color: $black;
          background-color: $clear;
          color: lighten($ml-teal, 15);
          border-left-color: $ml-teal;

          &.dropdown-toggle::before {
            border-left-color: $ml-teal;
          }

          &:focus,
          &:hover {
            background-color: $black;
            color: $white;
          }
        }

        &.open .dropdown-toggle {
          background-color: $black;
          color: var(--grayscale-lighter);
        }

        &.open .dropdown-menu {
          background-color: $black;

          & > li {
            & > a {
              color: var(--grayscale-light);
              padding-left: 3em;

              &:focus,
              &:hover {
                background-color: $clear;
                color: var(--grayscale-lighter);
              }
            }

            &.active a {
              background-color: $clear !important;
              border-left-color: $ml-teal;
              color: lighten($ml-teal, 15);

              &:focus,
              &:hover {
                color: lighten($ml-teal, 20);
              }
            }
          }
        }
      }
    }
  }

  // .navbar-default .navbar-nav > li > a,
  // .navbar-default .navbar-nav .open .dropdown-menu > li > a {
  //   border-left: 0.25rem solid $clear;
  //   color: $gray-lighter;
  // }

  // .navbar-default .navbar-nav > li.active > a,
  // .navbar-default .navbar-nav .open .dropdown-menu > li.active > a {
  //   border-left: 0.25rem solid $ml-teal;
  //   background-color: $clear;
  //   color: lighten($ml-teal, 10);
  //   font-weight: 500;
  // }

  // .navbar-default .navbar-nav > li > a:hover,
  // .navbar-default .navbar-nav > li > a:focus,
  // // .navbar-default .navbar-nav > li.active > a:hover,
  // // .navbar-default .navbar-nav > li.active > a:focus,
  // .navbar-default .navbar-nav > .open > a:hover,
  // .navbar-default .navbar-nav > .open > a:focus {
  //   color: $white;
  //   background-color: $black;
  // }

  // .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, 
  // .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
  // .navbar-default .navbar-nav .open .dropdown-menu > li.active > a:hover, 
  // .navbar-default .navbar-nav .open .dropdown-menu > li.active > a:focus {
  //   color: $white;
  //   background-color: $black;
  // }

  // .dropdown-toggle {
  //   @include set-fa('105',after);
  // }
  // .open .dropdown-toggle {
  //   @include set-fa('107',after);
  // }

  // .navbar-default .navbar-nav .open .dropdown-menu > li > a {
  //   color: #ddd;
  // }

  .acct-controls {
    display: none;
  }

  .main-nav {
    margin: 0 -15px;
  }

  .pre-nav {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }

  .navbar-default .navbar-brand {
    height: 30px;
    flex: 0 0 160px;
    padding: 0;
    position: relative;
    margin-top: beat(1.5);
    min-width: 160px;

    img {
      position: absolute;
      bottom: 0;
      margin-bottom: 0;
    }
  }

.main-nav {
    background-color: var(--inverse-bg);
    background-image: none;
  }

  .navbar-default .navbar-toggle {
    @include set-fa("0c9");

    text-align: center;
    background-color: rgba(255,255,255, 0.15);
    border-color: var(--comp-text-color);
    flex: 0 0 44px;
    height: 44px;
    min-width: 44px !important;

    &::before {
      font-size: 1.5rem;
      color: var(--grayscale-light);
      font-weight: 400;
      margin-right: 0;
    }

    &[aria-expanded="true"] {
      @include set-fa("00d");

      background-color: rgba(0, 0, 0, 0.25) !important;

      &::before {
        color: $white;
        margin-right: 0;
      }
    }

    &:focus,
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      background-image: none;
      border-color: var(--grayscale-light);
    }

    .fa {
      font-size: 1.5rem;
      font-weight: 400;
    }
  }

  // .navbar-toggler-icon {
  //   @include set-fa("0c9");

  //   color: $gray-lighter;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;

  //   &:before {
  //     margin: 0;
  //   }
  // }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: 100%;
  }

  // .global-nav {
  //   &.navbar-default .navbar-nav > li {
  //     border-bottom-color: pink;
  //   }

  //   &.navbar-default .navbar-nav > li > a {
  //     color: $gray-lighter;
  //   }
  // }

  #lightbox-bg {
    display: none;
    position: fixed;
    top: 56px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
}


@include print-screen {
  .navbar {
    display: block;
    position: absolute;
  }

  .navbar-default .navbar-brand {
    width: 200px;
    height: 40px;

    img {
      filter: none;
      position: relative;
      top: -49px;
      max-width: 90% !important;
    }
  }
}