
.text-separator {
font: var(--large-type);


  align-items: center;
  display: flex;
  padding: beat(1) 0;


  hr {
    flex: 1;
    margin: 0 0 0 1rem;
  }
}
