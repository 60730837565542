
address {
  font-style: normal;

  &.contact {
   
    li + li {
      margin-top: beat(1.5);
    }

    & + * {
      border-top: 1px solid var(--comp-border-color);
      margin-top: -1px;
    }
  }
}
