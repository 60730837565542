.data-set {
  margin: 0;

  dt {
    @extend %data-label;

    padding-left: $table-sides;
    padding-top: beat(1);
  }

  dd {
    @include underliner;

    margin-left: 0;
    padding-left: $el-pad;
    padding-right: $table-sides;
  }

  &.horizontal {
    align-items: stretch;

    dt {
      @include underliner;

      padding-left: $table-sides;
      padding-top: beat(1);
    }

    &.sublist dt,
    & dt.sub-item {
      padding-left: 1.25rem;
    }

    dd {
      padding-left: $table-sides;
      padding-top: beat(1);
    }


    &.mini-statement {

      --label-length: 7rem;

      & > *:nth-child(even) {
        flex: 0 0 var(--label-length);
      }

      & > *:nth-child(odd) {
        flex: 0 0 calc((100%) - (var(--label-length)));
      }


      dd {
        text-align: right;
      }

      &.total-up > *:last-of-type {
        --gray-safe: #636363;
        @extend %grayscale-shade;
        

        background-color: var(--comp-background-color);
        padding-top: beat(1);
      }

      &.total-up > *:last-child {
        font-weight: 700;
      }
    }
  }

  & + .data-series-separator {
    margin-top: beat(2);
  }
}

.list-inline {
  align-items: baseline;
  display: flex;
  list-style-type: none;
  padding: 0;

  li {
    margin-right: 1em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.data-series-separator {
font: var(--large-type);



  @include underliner;

  font-weight: 500;
  border-color: var(--grayscale-solid);
  padding-inline: $table-sides;
}

.data-nolabel {
  @include underliner;

  margin: 0;
  padding-inline: $table-sides;
  padding-top: beat(1);
}

.doc-list {
  list-style-type: none;
  padding-left: 1em;

  li {
    text-indent: -.65em;
  }


  a {
    @include set-fa('056');

    &[href$=".pdf"]::before {
      color: $adobe-red;
      content: '\f1c1';
    }

    &[href$=".tif"]::before {
      content: '\f1c5';
    }
  }
}

.pdf-list {
  list-style-type: none;
  padding-left: 1em;

  li {
    text-indent: -.65em;
  }


  a {
    @include set-fa("1c1");

    &::before {
      color: $adobe-red;
    }
  }
}

.block-link-menu {
  ul, ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  a.block-link {
    @include underliner;

    background-color: var(--comp-background-color);
    display: flex;
    text-decoration: none;
    padding-inline: $el-pad;
    padding-top: beat(1);
    position: relative;

    &:hover,
    &:focus {
      @extend %primary-outline;
      border-bottom-color: var(--primary-solid);
    }
  }

  &.with-icons {
    a.block-link {
      @include set-fa(105);
      padding-left: 2em;

      &::before {
        background-color: var(--grayscale-light);
        font-weight: 700;
        position: absolute;
        padding-top: 0.35em;
        display: flex;
        top: 0;
        left: 0;
        bottom: 0;
        width: 1.5em;
        justify-content: center;
      }

      &:hover::before {
        background-color: var(--primary-light);
      }
    }
  }
}

.block-link-menu-2 {
  ul, ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li > a {
    @extend %primary-outline;
    
    @include underliner(2);

    background-color: var(--comp-background-color);
    display: flex;
    text-decoration: none;
    padding-inline: $el-pad;
    padding-top: beat(2);
    position: relative;

    &:hover,
    &:focus {
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 80%);
    }

    &[data-state="selected"] {
      @extend %grayscale-solid;
      color: $white;

        &:hover,
        &:focus {
            background-image: none;
        }
    }
  }

  // &.with-icons {
  //   a.block-link {
  //     @include set-fa(105);
  //     padding-left: 2em;

  //     &::before {
  //       background-color: var(--grayscale-light);
  //       font-weight: 700;
  //       position: absolute;
  //       padding-top: 0.35em;
  //       display: flex;
  //       top: 0;
  //       left: 0;
  //       bottom: 0;
  //       width: 1.5em;
  //       justify-content: center;
  //     }

  //     &:hover::before {
  //       background-color: var(--primary-light);
  //     }
  //   }
  // }
}

