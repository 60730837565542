body {
  display: flex;
  flex-direction: column;
  padding-top: beat(24);
  min-height: calc((100vh) - (#{beat(24)}));

  &:has(*[class="header-public"]) {
    min-height: calc((100vh) - (#{beat(12)}));
    padding-top: beat(12);
  }
}

@include responsive(xs) {
  body {
    padding-top: beat(12);

    &:has(*[class="header-public"]) {
      min-height: calc((100vh) - (#{beat(8)}));
      padding-top: beat(8);
    }
  }
}

.alert-page {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-height: 400px;

  & > * {
    margin: 0 auto;
    max-width: $screen-sm;
    width: 100%;
  }
}