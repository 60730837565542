:root {
  // Grayscale tones
  --body-bg:            #{$body-bg-src};
  --body-text:          #{$body-text-src};
  --gray-safe:          #{$gray-safe};
  --gray-safe-comp:     #{darken($gray-safe, 1)};

  --svg-primary: invert(50%) sepia(100%) saturate(320%) hue-rotate(157deg) brightness(55%);
  --svg-invert: invert(100%) brightness(200%);

  @include comp-colors-outline('grayscale');


  --comp-bg:            #{$comp-bg-src};
  --comp-text:          #{$comp-text-src};
  --compshade-bg:       #{$compshade-bg-src};
  --compshade-text:     #{$compshade-text-src};

  --inverse-bg:         #{$inverse-bg-src};
  --inverse-text:       #{$inverse-text-src};

  --link-color:         #{$link-color};
  --link-hover-color:   #{$link-hover-color};

  .inverse {
    @extend %inverse-colors;
  }

  @each $colorname, $colormap in $palette {
    // --#{$colorname}-bg:       #{map-get($colormap, 'd-30')};
    --#{$colorname}-text:        #{map-get($colormap, 'd-50')};
    --#{$colorname}-solid:       #{map-get($colormap, 'd-30')};
    --#{$colorname}-light:       #{map-get($colormap, 'l-80')};
    --#{$colorname}-lighter:     #{map-get($colormap, 'l-90')};
  }
}