.btn {
  @extend %component;

  font-weight: 500;
  line-height: $base-line-height;

  &-primary {
    @extend %primary-solid;
  }

  &-info {
    @extend %info-solid;
  }

  &-warning {
    @extend %warning-solid;
  }

  &-danger {
    @extend %danger-solid;
  }

  &-success {
    @extend %success-solid;
  }

  &:focus,
  &:hover {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 80%);
  }
}

.btn-lg {
  font: var(--large-type);
}

.btn-sm {
  font: var(--small-type);
  padding: beat(1) $table-sides;
}

.btn-xs {
  font: var(--x-small-type);
  padding: beat(0.5) $table-sides;
}

.btn-default,
.main-content a.btn-default {
  @extend %primary-outline;

  background-color: var(--accent-light);

  &:focus,
  &:hover {
    background-color: var(--accent-lighter);
    border-color: var(--accent-solid);
    color: $black;
  }

  &:focus {
    outline: 2px solid $brand-primary;
  }
}

.btn-success,
.main-content a.btn-success {
  background-color: var(--success-text);
}

.btn-warning,
.main-content a.btn-warning {
  background-color: var(--warning-text);
}

.btn-link {
  background-color: $clear;
  border-color: $clear;
  color: $link-color;
  font-weight: 500;
  text-decoration: underline;

  &[data-icon] {
    text-decoration: none;
  }

  span {
    text-decoration: underline;
  }


  &:focus,
  &:hover {
    color: $link-hover-color;
  }
}

a.btn,
.main-content a.btn {
  color: $white;
  text-decoration: none;
  white-space: nowrap;

  &.btn-default {
    color: var(--primary-text);

    &:hover {
    }
  }

  &.btn-link {
    color: $link-color;
    text-decoration: underline;

    &[data-icon] {
      text-decoration: none;
    }

    span {
      text-decoration: underline;
    }

    &:hover {
      color: $link-hover-color;
    }
  }
}

.btn.fa-input {
  font-family: FontAwesome, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.btn-holder-md {
  display: flex;
  gap: $el-pad;
  margin-top: beat(4);
  padding: beat(2) 0;

  &.ctr {
    justify-content: center;
  }

  &.sequence,
  &.backflip {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  &.form-align {
    margin-left: var(--label-length);
  }
}

a.btn-print {
  @include set-fa("02f");
}

.btn-cta {
  font: var(--large-type);
  display: block;

  // width: 100%;
  width: auto;
}

.btn[disabled] {
  background-color: var(--grayscale-lighter) !important;
  border-color: var(--grayscale-solid);
  color: var(--grayscale-solid) !important;
  opacity: .5;

  &:hover {
    background-image: none;
    cursor: not-allowed;
  }
}

.btn-link[disabled] {
  background-color: $clear !important;
  border-color: $clear;
}

.btn[data-state="submission"] {
  @include set-fa("110");

  display: flex;

  &::before {
    display: block;
    animation: spin 1.25s linear infinite;
  }

  i {
    display: none;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.pdflink img {
  margin-right: 0.5rem;
}

* + .btn-rmv-util {
  margin-left: $grid-gutter-width;
}

.btn-rmv-util {
  @extend %danger-solid;

  font: var(--small-type);

  @include set-fa("014");

  padding: beat(0.5) $table-sides;
}

.btn-icon-home {
  @include set-fa("015");
}

.btn-link.cancel {
  @include set-fa("057");
}

.btn-viewdoc::before {
  background-color: $white;
  border-radius: 50%;
  margin-right: .65em;
  padding: .25em .5em;
}

.btn-group {
  .btn-xs {
    padding: 0 $el-pad 0 $table-sides;
  }
}
