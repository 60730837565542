.spread {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;

	& > * {
		flex: 1;
	}
}

.banner {
	padding: beat(2) 0;
}