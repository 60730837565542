.tab-prehead {

    background-color: $clear !important;

    small {
        background-color: var(--compshade-bg);
        border-radius: 0 8px 0 0;
        display: inline-block;
        padding: beat(1) $table-sides;
    }


    span {
        @include underliner;

        background-color: var(--comp-background-color);
        border-top: 1px solid  var(--comp-border-color);
        display: block;
        padding: beat(1) $table-sides;
    }


    &[data-state] {
        small {
            background-color: var(--comp-text-color);
            color:  $white;
        }
        span {
            background-color: var(--comp-background-color);
            border-color:  var(--comp-text-color);
        }
    }

    &[data-state="primary"] {
        small {
            background-color: var(--primary-solid);
            color:  $white;
        }
        span {
            background-color: var(--primary-lighter);
            border-color:  var(--primary-solid);
        }
    }

}