.header-public {
  background-color: var(--body-bg);
  border-bottom: 1px solid var(--comp-border-color);
  padding: beat(2) 0;
  position: fixed;
  top: 0;
  width: 100%;

  .navbar-brand {
    padding: 0;
    overflow: hidden;
    max-height: 50px;
  }
}

@include responsive(xs){
  .header-public {
    background-color: var(--inverse-bg);
    padding: beat(1) 0;

    .navbar-brand {
      position: relative;
      height: 30px;
      width: 100%;

      img {
        position: absolute;
        bottom: 0;
      }
    }
  }
};