caption,
.table caption {
    color: $gray-safe;

    &.section-title {
        color: var(--body-text);
        padding-bottom: beat(1);
        padding-inline: $table-sides;
    }


}

.table > thead > tr > th,
.table > tbody > tr > td {
    padding: beat(1) $table-sides beat(1);
}

h1, h2 {
    small {
        display: block;
        font-size: 0.69444em;
        font-weight: 400;
        padding: beat(1) 0;
    }
}

.page-header {
    @include underliner(2);

    margin-top: beat(4);

    & > * {
        margin: 0;
        padding: 0;
    }

    &.with-subhead {
        padding-bottom: 0;
        border-bottom: none;
    }

    &.with-prehead {
        padding-top: 0;
    }

    .sub-head {
        border-top: 1px solid var(--comp-border-color);
        margin-top: beat(1.75);
        padding-top: calc(#{beat(2)} - 1px);
        padding-bottom: beat(1);
    }

    .pre-head {
        padding: beat(1) 0;
    }

    &.with-button {
        display: flex;
        justify-content: space-between;

        .btn {
            padding: beat(1) $el-pad;
            text-decoration: none;
        }
    }
}

body > .page-header {
    margin-bottom: beat(1);

    .sub-head {
        @include strap-stretch;
    }
}

@include responsive(sm-up){
    body > .page-header {
        margin-bottom: beat(6);
    }
};


.flow > .page-header {
    & + * {
        margin-top: beat(2);
    }

    & > * {
        margin: 0;
        padding: 0;
    }
}

.input-group-addon {
    background-color: var(--comp-background-color);    
    color: var(--comp-text-color);    
    font-weight: 700;
}

.collapse__shell {
    & > *:first-child {
        margin-top: 0 !important;
        padding-top: beat(2);
    }

    & > *:last-child {
        margin-bottom: 0 !important;
        padding-bottom: beat(2);
    }
}

@include responsive(xs) {
    .page-header {
        margin-top: beat(1);
    }
}


.collapse {
    & > *:first-child {
        margin-top: 0 !important;
    }

    & > *:last-child {
        margin-bottom: 0 !important;
    }
}

// Tabs 

.nav-tabs {
    display: flex;
    gap: beat(2);
    justify-content: flex-start;
    border-bottom: 0;

    &::before,
    &::after {
        display: none;
    }

    & + .tab-content {
        border-top: 1px solid var(--comp-border-color);
    }

    li a {
        @extend %component;
        text-decoration: none;
        white-space: nowrap;
    }
    
    & > li.active > a,
    & > li.active > a:hover, 
    & > li.active > a:focus {
        color: var(--body-text);
    }
}

@include responsive(xs){
    .nav-tabs {
        gap: $table-sides;
        overflow-x: scroll;
        position: relative;
        top: 1px;

        & > li > a {
            padding: beat(1) $table-sides;
        }
    }
};

@include responsive(xxs){
    .nav-tabs {
        justify-content: space-between;

        li {
            flex: 1;
            text-align: center;
        }
     }
};

.modal-body {
    padding: beat(4) $el-pad;
}

.modal-footer {
    padding: beat(2) $el-pad;

    &:has(*[class^="btn"]) {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }

    .btn {
        text-align: center;
    
        & + .btn {
            margin-right: 0;
        }
    }

}
