.beta-label {
  animation-name: pulsar;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  background-color: lighten(#e3a853, 10);
  display: inline-block;
  font-weight: 700;
  margin-bottom: beat(2);
  padding: beat(1) $table-sides;
  text-align: center;
}

.jt-head {
  .beta-label {
    font: var(--x-small-type);
  }
}

@keyframes pulsar {
  0% {
    background-color: lighten(#e3a853, 5);
  }
  50% {
    background-color: lighten(#e3a853, 20);
  }
  100% {
    background-color: lighten(#e3a853, 5);
  }
}