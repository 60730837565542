.component {
  @extend %component;
}

.shaded {
  @extend %grayscale-shade;
}

.dark {
  @extend %grayscale-solid;
}

.inverse {
  background-color: var(--body-bg);
  color: var(--body-text);
}





.well {
  @extend %component;
  // @extend %grayscale-outline;
}