.table {
  border-bottom: 2px solid $gray;

  th {
    @extend %grayscale-solid;
    background-color: var(--comp-background-color);
    color: var(--comp-text-color);
    font: 500 var(--small-type);
  }

  tfoot > tr > td {
    padding: beat(2) $table-sides;
  }
}

.table-lead {
  padding: beat(0) $table-sides;
}

h1.table-lead {
  padding-top: beat(4);
  padding-bottom: beat(3);
}

@include print-screen() {
  .table th {
    background-color: var(--grayscale-bg) !important;
    color: var(--grayscale-solidtext) !important;
  }
};


table.table-twocol {
  thead tr th:first-child {
    width: 5rem;    
  }

  tbody tr td:first-child {
    font: var(--small-type);
    // padding-top: beat(1.75);
  }
}

table.table-multicol {
  tbody {
    td::before {
      @extend %data-label;

      display: none;
    }
  }
}


@include responsive(xs) {

  .page-table {
    .table-lead {
      padding-top: beat(1);
    }
  }

  .table-twocol {
    thead {
      display: none;
    }

    tbody tr {
      display: flex;
      flex-direction: column;

      td {
        display: block;
        flex: 1;

        &:first-child {
          background-color: var(--comp-background-color);
          font-weight: 700;
          padding-bottom: beat(0.75);
        }

        &:last-child {
          border-top-color: $clear;
        }
      }
    }

    &.table-striped > tbody > tr:nth-of-type(odd) {
      background-color: $clear;
    }
  }

  table.table-multicol {
    --label-length: 5rem;

    thead {
      display: none;
    }

    tbody,
    tfoot,
    tr,
    td {
      display: block;
    }

    tbody {
      tr {
        background-color: $clear !important;
      }

      tr > td:first-child {
        background-color: var(--comp-background-color);
        font-weight: 500;

        & ~ td {
          background-color: $clear;
        }
      }

      td {
        display: flex;
        align-items: baseline;
      }

      td::before {
        display: inline;
        width: var(--label-length);
      }
    }
  }
}