.aside {
  &__header {
    @include strap-stretch;

    border-bottom: 1px solid var(--comp-border-color);
    color: $gray-safe;
    font: italic var(--x-large-type-serif);
    margin-bottom: beat(2);
    padding-bottom: calc(#{beat(0.5)} - 1px);
    text-align: center;
  }

  &__header.sm {
    font: italic var(--large-type-serif);
  }

  &__header.lg {
    font: italic var(--xx-large-type-serif);
  }
}