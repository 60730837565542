@mixin setCardGallery($amt) {
  grid-template-columns: repeat(auto-fill, minmax(225px, calc((100% / #{$amt}) - #{$grid-gutter-width})));
}

.card__gallery {
  display: grid;
  gap: $grid-gutter-width;
  justify-content: center;

  .card {
    margin: 0 auto;
    max-width: 400px;
  }

  &[data-cards="2"] {
    @include setCardGallery(2);
  }

  &[data-cards="3"] {
    @include setCardGallery(3);
  }

  &[data-cards="4"] {
    @include setCardGallery(4);
  }

  &[data-cards="5"] {
    @include setCardGallery(5);
  }

  &[data-cards="6"] {
    @include setCardGallery(6);
  }
}

@include responsive(xxs) {
  .card__gallery[data-cards] {
    grid-template-columns: 1fr;
  }
}