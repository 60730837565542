.jumbotron {
    background-color: $shade25;
    background-size: cover;
    background-position: center;
    margin-bottom: 0;
    padding: beat(12) $el-pad;
    position: relative;

    h1 {
        font-size: 3rem;
        margin-top: 0;
    }

    small {
        display: block;
        font-size: 1.44rem;
        font-weight: 400;
        padding-top: beat(0.5);
    }

    .sub-head {
        display: block;
        font-size: 1.44rem;
        font-weight: 400;
        padding-top: beat(0.5);
    }
}

.jt-tag {
    @extend %component-shade-colors;

    margin-bottom: beat(6);

    p {
      font: var(--large-type);

      

        text-align: center;
        margin: 0;
        max-width: none;
    }
}

.new-jumbo {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, rgba(0, 0, 0, 0) 100%);
    background: url('/lmpublic/images/home/jt_home.jpg') no-repeat center center;
    background-size: cover;
    border-bottom: 10px solid var(--comp-border-color);


    .jumbo-grid {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr auto;
        height: beat(40);

        .jt-head {
            grid-column: 1/2;
            grid-row: 1/3;
            display: flex;
            align-items: center;
        }

        .usr-holder {}
    }
}

.usr-holder {
    background-color: var(--comp-border-color);
    grid-column: 2/3;
    grid-row: 2/3;
    padding: beat(1) $table-sides;

    .visited {
      font: var(--small-type);
    }
}


.jt-lead {
    // @extend %grayscale-outline;
    background-color: var(--comp-background-color);

    margin-bottom: beat(8);
    padding: beat(4) $el-pad;

    &>* {
        margin: 0 auto;
        max-width: $screen-lg / 2;
        text-align: center;
    }
}

@include responsive(xxs){
    .usr-holder {
        grid-column: 1/-1;
        margin: 0 -$el-pad;
        padding: beat(1) $el-pad beat(3);
    }
};



