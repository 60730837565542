.flow {
  & > * + * {
    margin-top: beat(4);
  }

  & > li + li {
    margin-top: beat(1);
  }

  & > p + ul,
  & > dd + dt {
    margin-top: beat(2);
  }

  & > h2 {
    margin-top: beat(8);

    &:first-child {
      margin-top: 0;
    }

    & + * {
      margin-top: beat(2);
    }
  }

  & > h3 {
    & + * {
      margin-top: beat(2);
    }
  }

  & > h4 {
    padding-top: beat(1);

    & + * {
      margin-top: beat(1);
    }
  }

  & > h5, h6 {
    & + * {
      margin-top: 0;
    }
  }
}

form.flow {
  input[type=hidden] + * {
    margin-top: 0;
  }
}