%section-row {
  @include underliner(1);

  padding-inline: $table-sides;
  padding-top: beat(1);
}

.section-head {
  @extend %grayscale-solid;

  color: var(--comp-text-color);
  background-color: var(--comp-background-color);
  display: flex;
  justify-content: space-between;
  padding: beat(0.5) $table-sides;
}

.section-title {
  font: 500 var(--large-type);

  small {
    display: block;
  }
}

.subsection-head {
  @extend %section-row;

  display: flex;
  justify-content: space-between;
}

* > * + .subsection-head {
  padding-top: beat(2);
}

.section-body {
  @extend %section-row;

  & > .section-key:first-child {
    margin-top: beat(-1);
  }
}

.section-key {
  @extend %section-row;

  background-color: var(--comp-background-color);
  font-weight: 500;
  margin-bottom: beat(1);
  margin-inline: -$table-sides;

  &:last-child {
    margin-bottom: beat(-1);
  }
}


.btn-sectionhead {
    align-items: center;
    display: flex;
    font: var(--small-type);
    padding: 0 $table-sides;
}

.section-head {
  .btn-sectionhead {
    background-color: rgba(255, 255, 255, 0.75);
    color:  var(--body-text) !important;
  }
}

.subsection-head {

  &:has(.btn-sectionhead) {
    align-items: baseline;
  }

  .btn-sectionhead {
    @include set-fa("044");

    color: $link-color !important;

    &:focus,
    &:hover {
      background-color: var(--body-bg);
      border-color: var(--comp-border-color);
    }
  }
}

@include print-screen {
  .section-head {
    background-color: var(--inverse-bg) !important;

    & > * {
      color: white !important;
    }
  }
}