html {
  font-family: $font-family;
  font-size: $base-font-size;
  height: 100%;
}

@include responsive(xs) {
  html {
    font-size: $nrw-font-size;
  }
}


@include print-screen {
  html {
    font-size: $print-font-size;
  }
}


body {
  font-size: 1rem;
  line-height: $line-height;

  // Margin killer
  * {
    margin: 0;
  }
}

%header-type {
  letter-spacing: 0.02em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend %header-type;
}

h1 {
  font: 300 var(--primary-head);
}

h2 {
  font: 300 var(--xx-large-type);
}

h3 {
  font: 400 var(--x-large-type);
}

h4 {
  font: 500 var(--large-type);
}

h5 {
  font: 500 var(--base);
}

p {
  max-width: $type-metre;

  &.lead {
    font: var(--large-type);
  }
}

h6 {
  font: 700 var(--small-type);
}

ul.flat {
  list-style-type: none;
  padding-left: 0;
}

address {
  font-style: normal;

  &.address-display {
    @extend %component;

    border-color: $clear;
  }
}

.footnote,
tfoot {
  font: var(--small-type);
}

.main-content {
  a {
    @extend %text-link;
    // color: $link-color;
    // font-weight: 500;

    // &:focus,
    // &:hover {
    //   color: $link-hover-color;
    // }
  }
}