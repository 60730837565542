footer {
  margin-top: auto;
  padding-top: beat(12);

  .wrapper {
    position: relative;
  }

  .footer-head {
  font: var(--large-type);

  

    @include underliner;
    @include strap-stretch;

    font-weight: normal;
  }

  .list-inline {
    align-items: center;
    margin-top: beat(0.5);

    a {
      color: $link-color;
      text-decoration: none;
      font-weight: 500;

      &:hover {
        color: $link-hover-color;
      }
    }

    // & > *:last-child {
    //   margin-left: auto;
    // }
  }

  .navbar-fixed-bottom {
    display: none;
  }
}

.copyright {
  display: flex;
  flex-wrap: wrap;
  font: var(--x-small-type);
  justify-content: center;
  list-style-type: none;
  padding: beat(4) 0 beat(2);
  margin: 0;

  a {
    color: $link-color;

    &:hover {
      color: $link-hover-color;
    }
  }

  li + li {
    &:before {
      content: '|';
      margin: 0 0.75rem;
    }
  }
}

@include responsive(xs) {
  footer .navbar-fixed-bottom {
    background-color: var(--comp-background-color);
    display: flex;
    justify-content: stretch;
  }

  .mobile-nav {
    display: flex;
    flex-basis: 100%;
    justify-content: space-around;
    margin: 0;

    &.nav li a {
      align-items: center;
      color: var(--comp-text-color);
      display: flex;
      flex-direction: column;
      justify-content: center;
      line-height: 1.25;
      padding: beat(2) beat(1);
      text-decoration: none;

      &::before {
        margin-right: 0;
      }

      &:focus,
      &:hover {
        background-color: $white;
        color: $black;
      }

    }

    &.nav li.contact a {

      &::before {
        filter: invert(40%);
      }
    }

    &.nav li.contact a.collapsed {
      color: var(--comp-text-color);
      filter: invert(0%);
    }

  }

  .nav {
    .msgctr {
      .badge {
        top: 5px;
        left: 20px;
      }
    }
  }



  .footer-head,
  #footer_form {
    display: none;
  }

  .copyright {
    padding-top: beat(6);
    padding-bottom: beat(14);
  }
}


.contact-slider {
  background-color: $white;
  border-top: 1px solid var(--grayscale-solid);
  box-shadow: 0px -3px 15px rgba(0,0,0,0.2);
  position: fixed;
  z-index: 1020;
  bottom: 0;
  left: 0;

  .shell {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: beat(2) $el-pad beat(12);

    h3 {
      font-weight: 500;
      color: $body-text;
    }

    dl {
      border-top: 1px solid var(--comp-border-color);
      flex-basis: 100%;
      margin-top: beat(1);
      padding-top: calc((#{beat(2)}) - (1px));

      a {
        color: $link-color;

        &:focus,
        &:hover {
          color: $link-hover-color;
        }
      }

      dt {
        padding: 0;
        font-weight: 700;
      }
      dd {
        padding: 0 $el-pad beat(2);

        & + dt {
          @include strap-stretch;
          border-top: 1px solid var(--comp-border-color);
          padding-top: beat(2);
        }
      }
    }
  }
}

@include responsive(sm-up){
  .contact-slider.collapse.in {
    display: none;
  }
};

@include responsive(xs){
  .footer-public {
    .utility-menu {
      display: none;
    }
    .copyright {
      padding-bottom: beat(1);
    }
  }
}
@include responsive(xxs){
  .footer-public {

    .list-inline {
      flex-wrap: wrap;
      justify-content: center;

      li:last-child {
        order: -10;
        flex-basis: 100%;
        padding-bottom: beat(2);
        text-align: center;
      }

    }

    .copyright {
      line-height: 1.75;
      padding-top: beat(2);

      & > *:first-child {
        flex-basis: 100%;
        text-align: center;

        & + li::before {
          margin: 0;
          content: '';
        }
      }

    }
  }
};

@media only screen and (max-width: 300px) {
  .nav.mobile-nav li a span {
    display: none;
  }
}