/*  5a. Color System  */


$hueAngle: 180;

// Define the color base
$brand: (
  grayscale: $gray,
    primary: $brand-primary, // #1169c3
     // accent: adjust-hue($brand-primary, 180), // #713718
    // Inserting Original Orange
     accent: $ml-blue-light, // #dd942b
  secondary: adjust-hue($brand-primary, $hueAngle),
   // tertiary: adjust-hue($brand-primary, -($hueAngle)),
   tertiary: $ml-teal, //#007d85
quarternary: #491506, // Burnt Sienna
       info: $info-base, 
    warning: $warning-base, 
     danger: $danger-base, 
    success: $success-base
);

// System Palette
$grayscale: set-color-map($gray);
$primary: set-color-map($brand-primary);
$accent: set-color-map(map-get($brand, 'accent'));
$secondary: set-color-map(map-get($brand, 'secondary'));
$tertiary: set-color-map(map-get($brand, 'tertiary'));
$quarternary: set-color-map(map-get($brand, 'quarternary'));

// System States
$info: set-color-map(map-get($brand, 'info'));
$warning: set-color-map(map-get($brand, 'warning'));
$danger: set-color-map(map-get($brand, 'danger'));
$success: set-color-map(map-get($brand, 'success'));

// Palette Map
$palette: (
// System Palette
  grayscale: $grayscale,
  primary: $primary,
  accent: $accent,
  secondary: $secondary,
  tertiary: $tertiary,
  quarternary: $quarternary,
// System States
  info: $info,
  warning: $warning,
  danger: $danger,
  success: $success
);

// Text/Element colors
// Tone / Grayscale values
$body-bg-src:     $white;
$body-text-src:   map-get($grayscale, 'd-90');

$comp-bg-src:       map-get($grayscale, 'l-100');
$comp-text-src:     map-get($grayscale, 'd-100');

$compshade-bg-src:       map-get($grayscale, 'l-80');
$compshade-text-src:     $black;

$inverse-bg-src:    map-get($grayscale, 'd-100');
$inverse-text-src:  map-get($grayscale, 'l-100');
