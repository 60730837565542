$body-bg:           var(--body-bg, $body-bg-src);
$body-text:         var(--body-text, $body-text-src);

$comp-bg:           var(--comp-bg, $comp-bg-src);
$comp-text:         var(--comp-text, $comp-text-src);

$compshade-bg:      var(--compshade-bg, $compshade-bg-src);
$compshade-text:    var(--compshade-text, $compshade-text-src);

$inverse-bg:        var(--inverse-bg, $inverse-bg-src);
$inverse-text:      var(--inverse-text, $inverse-text-src);

%body-colors {
  background-color: $body-bg-src;
  background-color: $body-bg;
  color: $body-text-src;
  color: $body-text;
}
%component-colors {
  background-color: $comp-bg-src;
  background-color: $comp-bg;
  color: $comp-text-src;
  color: $comp-text;
}
%component-shade-colors {
  background-color: $compshade-bg-src;
  background-color: $compshade-bg;
  color: $compshade-text-src;
  color: $compshade-text;
}
%inverse-colors {
  background-color: $inverse-bg-src;
  background-color: $inverse-bg;
  color: $inverse-text-src;
  color: $inverse-text;
}

%gray-safe {
  color: $gray-safe;
  color: var(--gray-safe, $gray-safe);
}

%text-link {
  color: var(--link-color);
  font-weight: 500;

  &:focus,
  &:hover {
    color: var(--link-hover-color);
  }

}

// Text Color
@each $colorname, $colormap in $palette {
  %#{$colorname}-text {
    color: map-get($colormap, 'd-20');
    color: var(--#{$colorname}-text, #{map-get($colormap, 'd-20')});
  }
}

%component {
  background-color: var(--comp-background-color);
  border: 1px solid var(--comp-border-color);
  border-radius: $border-radius-base;
  color: var(--comp-text-color);
  padding: calc(#{beat(2)} - 1px) $el-pad;
}

%grayscale-solid {
  @include comp-colors-solid('grayscale');

  & {
    --gray-safe: #{lighten($gray-safe, 49)};
  }
}

%grayscale-shade {
  @include comp-colors-shade('grayscale');

  & {
    --gray-safe: #{darken($gray-safe, 7)};
  }
}

%grayscale-outline {
  @include comp-colors-outline('grayscale');

  & {
    --gray-safe: #{darken($gray-safe, 1)};
  }
}

%primary-solid {
  @include comp-colors-solid('primary');
}

%primary-outline {
  @include comp-colors-outline('primary');
}

%secondary-solid {
  @include comp-colors-solid('secondary');
}

%secondary-outline {
  @include comp-colors-outline('secondary');
}

%tertiary-solid {
  @include comp-colors-solid('tertiary');
}

%tertiary-outline {
  @include comp-colors-outline('tertiary');
}

%quarternary-solid {
  @include comp-colors-solid('quarternary');
}

%quarternary-outline {
  @include comp-colors-outline('quarternary');
}

%accent-solid {
  @include comp-colors-solid('accent');
}

%accent-outline {
  @include comp-colors-outline('accent');
}

%info-solid {
  @include comp-colors-solid('info');
}

%info-outline {
  @include comp-colors-outline('info');
}

%warning-solid {
  @include comp-colors-solid('warning');
}

%warning-outline {
  @include comp-colors-outline('warning');
}

%danger-solid {
  @include comp-colors-solid('danger');
}

%danger-outline {
  @include comp-colors-outline('danger');
}

%success-solid {
  @include comp-colors-solid('success');
}

%success-outline {
  @include comp-colors-outline('success');
}

%iconic {
  display: flex;
  gap: $icon-kern;

  &::before {
    flex: 0 0  $icon-size;
    font-family: 'FontAwesome';
    font-weight: 700;
    text-align: center;
  }
}

%data-label {
  @extend %gray-safe;
  font: 500 var(--small-type);
}

%vertical-expander {
  display: flex;
  flex-direction: column;

  *[class$="__head"],
  *[class$="__foot"] {

    * {
      margin: 0;
    }
  }
  *[class$="__body"] {
    flex: 1;

  }

}
