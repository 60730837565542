/*  5b. Type System  */

@function power($exponent, $value, $factor) {
  @if $exponent > 0 {
    @for $i from 1 through $exponent {
      $value: $value * $factor;
    }
  } @else if $exponent < 0 {
    @for $i from 1 through -$exponent {
      $value: $value / $factor;
    }
  }

  @return $value;
}

$type-rhythm-px: $base-font-size * $line-height;

@function typeset($pwr, $base, $scale) {
  $size-adjustor: power($pwr, 1, $scale);
  $height-adjustor: power($pwr * -1, $line-height, $scale);

  @return #{$size-adjustor}em/$height-adjustor $font-family;
}

@function typeset-serif($pwr, $base, $scale) {
  $size-adjustor: power($pwr, 1, $scale);
  $height-adjustor: power($pwr * -1, $line-height, $scale);

  @return #{$size-adjustor}em/$height-adjustor $font-serif;
}


// Typography Custom Properties 
// ------------------

// Set the desktop values
:root {
  @each $size-name, $scale-step in $type-map {
    --#{$size-name}: #{typeset($scale-step, $base-font-size, $type-scale)};

    @if ($scale-step > 0) and ($scale-step < 4) {
      --#{$size-name}-serif: #{typeset-serif($scale-step, $base-font-size, $type-scale)};
    }
  }
  
  // Set the device values
  @include responsive(xs) {
    @each $size-name, $scale-step in $type-map {
      --#{$size-name}: #{typeset($scale-step, $nrw-font-size, $nrw-type-scale)};

      @if ($scale-step > 0) and ($scale-step < 4) {
        --#{$size-name}-serif: #{typeset-serif($scale-step, $nrw-font-size, $nrw-type-scale)};
      }
    }
  }

  // Set the print values
  @include print-screen() {
    @each $size-name, $scale-step in $type-map {
      --#{$size-name}: #{typeset($scale-step, $print-font-size, $print-type-scale)};

      @if ($scale-step > 0) and ($scale-step < 4) {
        --#{$size-name}-serif: #{typeset-serif($scale-step, $print-font-size, $print-type-scale)};
      }
    }
  }
}
