// Iconogrpahy

*[data-icon] {
  @extend %iconic;
}

*[data-icon="doc"]::before {
  content: '\f0f6';
}

*[data-icon="pdf"]::before {
  color: $adobe-red;
  content: '\f1c1';
}

*[data-icon="img"]::before {
  content: '\f1c5';
}

*[data-icon="download"]::before {
  content: '\f019';
}

*[data-icon="upload"]::before {
  content: '\f093';
}

*[data-icon="edit"]::before {
  content: '\f044';
}

*[data-icon="print"]::before {
  content: '\f02f';
}

*[data-icon="ext"]::before {
  content: '\f08e';
}

*[data-icon="calc"]::before {
  content: '\f1ec';
}

*[data-icon="plus"]::before {
  content: '\f055';
}

*[data-icon="check"]::before {
  content: '\f058';
}

*[data-icon="minus"]::before {
  content: '\f056';
}

*[data-icon="close"]::before {
  content: '\f057';
}

*[data-icon="phone"]::before {
  content: '\f095';
}

*[data-icon="calendar"]::before {
  content: '\f133';
}

*[data-icon="remove"]::before {
  content: '\f014';
}

*[data-icon="cert"]::before {
  content: '\f0a3';
}

*[data-icon="envelope"]::before {
  content: '\f003';
}

*[data-icon="home"]::before {
  content: '\f015';
}

*[data-icon="back"]::before {
  content: '\f100';
}

*[data-icon="refresh"]::before {
  content: '\f021';
}

*[data-icon="lock"]::before {
  content: '\f023';
}

*[data-icon="continue"] {
  @include set-fa('101','after');
  padding-left: $el-pad !important;
}

.fixed-icon {
  display: flex;
}

a.fixed-icon {
  display: inline-block;
  padding-left: 1.45rem;
  position: relative;

  &::before {
    position: absolute;
    left: 0;
  }
}




// Utility Menu Icons

.utility-menu {

  .btn-home {
    a {
      @include set-fa('015');
    }
  }

  .msgctr {
    a {
      @include set-fa('003');
    }
  }

  .profile {
    a {
      @include set-fa('007');
    }
  }

  .upload {
    a {
      @include set-fa('093');
    }
  }

  .logout {
    a {
      @include set-fa('011');
    }
  }

  .phone {
    span {
      @include set-fa('095');
    }
  }
  .external {
    a {
      @include set-fa('08e');
    }
  }

  .calendar {
    a {
      @include set-fa('073');
    }
  }

  .contact {
    a::before {
     content: url('../img/lacera-mark.svg');
     height: 17px;
    }
  }


}