.collapsor {
  display: grid;
  grid-template-rows: 1fr;
  transition: grid-template-rows .25s ease;

  &[aria-hidden] {
    grid-template-rows: 0fr;
  }

  & > * {
    overflow: hidden;
  }
}

.collapsor-toggle {
  border: none;
  background-color: $clear;

  &::before {
    content: "";
    border: 6px solid transparent;
    border-left-color: #000;
    border-right-width: 0;
    display: inline-block;
    margin-right: 3px;
  }

  &[aria-expanded]::before {
    transform: rotate(90deg);
  }

}