table.dataTable {
  clear: both;

  // Removing these - doesn't work with our existing styles
  // Resetting margin to 0 
  // Keep an eye out for problems
  //      margin-top: 6px !important;
  //      margin-bottom: 6px !important;
  margin: 0 !important;
  max-width: none !important;

  td, th {
    box-sizing: content-box;

    &.dataTables_empty {
      text-align: center;
    }
  }

  &.nowrap th,
  &.nowrap td {
    white-space: nowrap;
  }
}

.flag-01 {
  display: none !important;
  background-color: rgba($gray, 0.5) !important;
}

.dataTables_wrapper {
  // div.dataTables_length {

  //      Not in use on Member Portal
  //      Repurposed as "table-header" class

  //     label {
  //         font-weight: normal;
  //         text-align: left;
  //         white-space: nowrap;
  //     }

  //     select {
  //         width: 75px;
  //         display: inline-block;
  //     }
  // }

  .two-col {
    // align-items: flex-end;
    align-items: center;
    gap: beat(1) $el-pad;
    margin-bottom: beat(1);
    padding: 0 $el-pad;

    & > * {
      padding: 0;
    }
  }

  @include responsive(xs){
    .two-col {
      flex-direction: column;
      align-items: stretch;
      margin-bottom: 0;
    }
  };

  h1.table-lead {
    padding-bottom: beat(2);
  }

  // div.table-header {
  //   .table-lead {
  //     @include responsive(xs) {
  //       position: relative;
  //     }
  //   }

  //   h3.table-lead {
  //     @include responsive(sm-up) {
  //       padding-top: beat(2.5);
  //       padding-bottom: beat(1.5);
  //     }


  //     @include responsive(xs) {
  //       padding-top: beat(2.5);
  //       padding-bottom: beat(1.5);
  //     }
  //   }

  //   h4.table-lead {
  //     @include responsive(sm-up) {
  //       padding-top: beat(2.5);
  //       padding-bottom: beat(1.5);
  //     }


  //     @include responsive(xs) {
  //       padding-top: beat(2.5);
  //       padding-bottom: beat(1.5);
  //     }
  //   }
  // }

  .search-box {
    flex: 0 1 auto;
  }

  div.dataTables_filter {
    display: flex;
    text-align: right;
    padding-top: beat(1);
    padding-right: $table-sides;


    @include responsive(xs) {
      padding: beat(1) $el-pad;
      background-color: var(--comp-background-color);


      @include strap-stretch;
    }


    // label {
    //   font-weight: 400;
    //   display: flex;
    //   justify-content: flex-end;
    //   align-items: stretch;

    //   .form-control.input-sm {
    //     flex: 0 1 15rem;
    //     min-height: 40px;

    //     @include responsive(xs) {
    //       max-height: beat(5);
    //       padding: 2px 10px;
    //     }
    //   }

    //   input:focus::-webkit-input-placeholder {
    //     color: transparent;
    //   }

    //   input:focus:-moz-placeholder {
    //     color: transparent;
    //   }
    // }

    .form-control {
      border-radius: $border-radius-base 0 0 $border-radius-base !important;
    }

    .btn-default {
      @include set-fa("00d");

      background-color: var(--grayscale-lighter);
      border-color: #ccc;
      padding: 0 $el-pad;

      &::before {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color:  var(--grayscale-solid);
        margin-right: 0;
      }

      &:focus,
      &:hover {
        cursor: pointer;
        background-color: var(--comp-background-color);

        &::before {
          color:  var(--grayscale-text);
        }
      }
    }
  }

  div.dataTables_info {
    text-align: center;

  font: var(--small-type);

    margin-bottom: 0;
    padding: beat(2) 0 0;
    color: $gray-safe;
    white-space: nowrap;

    @include responsive(xs) {
      padding: 0;
      margin: beat(2) 0 0;
    }
  }

  // div.dataTables_paginate {
  //     margin: 0;
  //     white-space: nowrap;
  //     text-align: right;

  //     ul.pagination {
  //         margin: 2px 0;
  //         white-space: nowrap;
  //     }
  // }

  div.dataTables_paginate {
    white-space: nowrap;
    text-align: center;

    @include responsive(sm-up) {
      margin: 0;
    }


    @include responsive(xs) {
      margin-bottom: beat(2);
    }


    ul.pagination {
      margin: 5px 0;
      white-space: nowrap;

      @include responsive(xs) {
        margin: 6px 0 7px;
      }


      li {
        padding-left: 0 !important;

        a {
          padding: 6px 0.65em;

          @include responsive(xs) {
            padding: 3px 9px;
          }
        }
      }

      .active {
        a {
          @extend %primary-outline;
        }
      }
    }
  }

  div.dataTables_processing {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
  }

  @include responsive(xs) {
    // div.dataTables_length,
    div.dataTables_info,
    div.dataTables_paginate {
      text-align: center;
    }

    div.dataTables_filter {
      text-align: left;
    }
  }
}

@include responsive(xs) {
  div.dataTables_wrapper {
    & > .row:first-child {
      display: block;
      margin-bottom: 4px;

      h2 {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .toggle-filter-local {
      @include set-fa("002");
        padding: $table-sides;

      &::before {
        font-size: .8889rem;
        margin-right: 0;
      }
    }
  }
}


/*
    * Sorting Styles
    * DEACTIVATED: Reactivate if necesarry
*/

// table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting,
// table.dataTable thead > tr > td.sorting_asc,
// table.dataTable thead > tr > td.sorting_desc,
// table.dataTable thead > tr > td.sorting {
//     padding-right: 30px;
// }

// table.dataTable thead > tr > th:active,
// table.dataTable thead > tr > td:active {
//     outline: none;
// }

// table.dataTable thead .sorting,
// table.dataTable thead .sorting_asc,
// table.dataTable thead .sorting_desc,
// table.dataTable thead .sorting_asc_disabled,
// table.dataTable thead .sorting_desc_disabled {
//     cursor: pointer;
//     position: relative;
// }

// table.dataTable thead .sorting:after,
// table.dataTable thead .sorting_asc:after,
// table.dataTable thead .sorting_desc:after,
// table.dataTable thead .sorting_asc_disabled:after,
// table.dataTable thead .sorting_desc_disabled:after {
//     position: absolute;
//     bottom: 8px;
//     right: 8px;
//     display: block;
//     font-family: 'Glyphicons Halflings';
//     opacity: 0.5;
// }

// table.dataTable thead .sorting:after {
//     opacity: 0.2;
//     content: "\e150";

//     /* sort
// }

// table.dataTable thead .sorting_asc:after {
//     content: "\e155";

//     /* sort-by-attributes
// }

// table.dataTable thead .sorting_desc:after {
//     content: "\e156";

//     /* sort-by-attributes-alt
// }

// table.dataTable thead .sorting_asc_disabled:after,
// table.dataTable thead .sorting_desc_disabled:after {
//     color: #eee;
// }

/*
    * Scrolling Styles
    * DEACTIVATED: Reactivate if necesarry
*/

// div.dataTables_scrollHead table.dataTable {
//     margin-bottom: 0 !important;
// }

// div.dataTables_scrollBody table {
//     border-top: none;
//     margin-top: 0 !important;
//     margin-bottom: 0 !important;
// }

// div.dataTables_scrollBody table thead .sorting:after,
// div.dataTables_scrollBody table thead .sorting_asc:after,
// div.dataTables_scrollBody table thead .sorting_desc:after {
//     display: none;
// }

// div.dataTables_scrollBody table tbody tr:first-child th,
// div.dataTables_scrollBody table tbody tr:first-child td {
//     border-top: none;
// }

// div.dataTables_scrollFoot table {
//     margin-top: 0 !important;
//     border-top: none;
// }

/*
    * Condensed Styles
    * DEACTIVATED: Reactivate if necesarry
*/
// table.dataTable.table-condensed {

//     & > thead > tr > th {
//         padding-right: 20px;
//     }

//     .sorting:after,
//     .sorting_asc:after,
//     .sorting_desc:after {
//         top: 6px;
//         right: 6px;
//     }
// }

/*
    * Bordered Styles
    * DEACTIVATED: Reactivate if necesarry
*/

// table.table-bordered.dataTable {
//     border-collapse: separate !important;

//     th, td {
//         border-left-width: 0;

//         &.:last-child {
//             border-right-width: 0;
//         }
//     }

//     tbody {
//         th, td {
//             border-bottom-width: 0;
//         }
//     }
// }

// div.dataTables_scrollHead table.table-bordered {
//     border-bottom-width: 0;
// }

/*
    * Responsive Styles
    * DEACTIVATED: Reactivate if necesarry
*/
// div.table-responsive > div.dataTables_wrapper > div.row {
//     margin: 0;

//     & > div[class^="col-"]:first-child {
//         padding-left: 0;
//     }

//     & > div[class^="col-"]:last-child {
//         padding-right: 0;
//     }
// }

.toggle-filter-local,
#toggle-filter-pg {
  @include responsive(sm-up) {
    display: none;
  }


  @include responsive(xs) {
    font-size: $base-font-size !important;
    padding: 6px 8px 6px 9px;
  }
}

@include responsive(xs) {
  #toggle-filter-pg {
    position: absolute;
    right: $el-pad;
  }
}


.dataTables_wrapper .search-box {
  @include responsive(sm-up) {
    display: block;
  }


  // @include responsive(xs) {
  //     .dataTables_filter {
  //         padding-bottom: 2px;

  //         .input-group {
  //             margin-bottom: 0;
  //         }
  //     }
  // }
  // @include responsive(xxs) {
  //     .in .dataTables_filter {
  //         padding-bottom: 1px;
  //     }
  // }
}

@include print-screen() {
  .toggle-filter-local {
    display: none;
  }

};
