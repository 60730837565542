.address-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  & > * {
    flex: 1 0 100%;

    &.city {
      flex: 0.9;
    }

    &.state {
      max-width: 3.75rem;
    }

    &.zip {
      max-width: 7.5rem;
    }
  }
}

@include responsive(xs){
  .address-grid {
    & > .state {
      max-width: 4.25rem;
    }
  }
};

@include responsive(xxs) {
  .address-grid {
    flex-direction: column;
  }
}

.contact-form {
  // Specific to Disability - move in future release
  .form-group + .address-grid {
    border-top: 1px solid var(--grayscale-solid);
    margin-top: beat(6);
    padding-top: beat(2);
  }

  .address-grid + .phone-ext {
    border-top: 1px solid var(--grayscale-solid);
    margin-top: beat(6);
    padding-top: beat(2);

    .form-group {
      margin-top: beat(2);
    }
  }

  hr {
    margin-top: beat(6);
    border-color: $clear;
    border-top-color: var(--comp-border);
  }
}

.phone-ext {
  margin-top: 0;

  .form-group {
    margin-top: beat(4);

    &.h5-tel .form-control {
      max-width: 12rem;
    }

    &.ext .form-control {
      max-width: 5.5rem;
    }
  }
}

.form-group.ext div {
  align-items: top;
  display: flex;

  .control-field {
    display: flex;
    flex-direction: column;
  }

  label {
    line-height: 2.5;
    width: 5rem;
    padding-right: 1rem;
  }
}

@include responsive(xs-up) {
  .phone-ext {
    display: flex;
    gap: 3rem;
  }

  .form-group.ext div {
    label {
      text-align: right;
      width: 2rem;
    }
  }
}
