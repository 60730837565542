.video {
  &_outer {
    max-width: 700px;
  }

  &_wrapper {
    height: 0;
    margin-bottom: beat(4);
    padding-bottom: 56.25%;

    /* 16:9 */
    position: relative;

    & + .footnote {
      margin-top: beat(-2);
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}