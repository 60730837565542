.card {
  @extend %component;

  @extend %vertical-expander;

  @include card-shadow;

  border-radius: $border-radius-large;
  padding: 0;
  position: relative;

  & > *:first-child {
    border-radius: $border-radius-large $border-radius-large 0 0;
    overflow: hidden;
  }

  & > *:last-child {
    border-radius: 0 0 $border-radius-large $border-radius-large;
    overflow: hidden;
  }

  & > * + * {
    border-top: 1px solid var(--comp-border-color);
  }

  &__head {
    @extend %component;

    padding: beat(0.5) $table-sides;
    text-align: center;
  }

  &__title {
    font: var(--large-type);
    font-weight: 500;
  }

  &__body {
    background-color: var(--body-bg);
    padding: beat(2) $table-sides;
  }

  &__foot {
    padding: beat(1) $table-sides;
  }

  &__body + &__foot {
    border-top: 1px solid var(--comp-border-color);
  }

  &__foot {
    background-color: var(--comp-background-color);
    padding: beat(2) $el-pad;

    &-linked {
      display: flex;
      flex-direction: column;

      .card__link {
        @extend %primary-outline;

        background-color: var(--accent-light);
        display: block;
        padding: beat(2) $el-pad beat(2) 3.2rem;
        position: relative;

        &::before {
          position: absolute;
          left: $grid-gutter-width;
        }

        &:focus,
        &:hover {
          background-color: var(--accent-lighter);
        }

        & + * {
          border-top: 1px solid var(--comp-border-color);
        }
      }
    }
  }

  &__badge {
    @extend %component;

    border-color: var(--comp-text-color);
    border-radius: $border-radius-small;
    font: var(--small-type);
    line-height: $line-height;
    font-weight: 700;
    position: absolute;
    padding: 1px 5px;
    top: -1rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
  }

  &__shell {
    display: flex;
    flex-direction: column;

    .card {
      flex: 1;
    }
  }

  .data-set {
    background-color: var(--body-bg);
    margin-bottom: 0;
  }

  .data-set > *:last-of-type {
    border-bottom-color: $clear;
  }

  &[data-state="primary"] {
    border: 1px solid var(--primary-solid);

    .card__head {
      @extend %primary-solid;

      padding: beat(1) $table-sides;
    }
  }
}

.card-download {
  text-align: center;

  img {
    margin: 0 auto;
  }
}

.card__body[data-cardicon] {
  @include set-fa("101");

  &::before {
    font-size: 5rem;
    line-height: 1;
    display: block;
    margin-bottom: beat(2);
    margin-right: 0;
    text-align: center;
  }

  &[data-cardicon="pdf"]::before {
    content: '\f1c1';
    color: hsl(4, 100%, 33%);
  }

  &[data-cardicon="upload"]::before {
    content: '\f093';
  }

  &[data-cardicon="prfl"]::before {
    content: '\f007';
  }

  &[data-cardicon="bene"]::before {
    content: '\f03a';
  }
}

// Custom Alerts

.card[data-state="reqdoc"] {

  .card__badge {
    @extend %inverse-colors;
  }

  .card__head {
    @extend %primary-solid;

    padding: beat(2) $el-pad beat(1);
  }

  .flow {
    & > * + * {
      margin-top: beat(2);
    }
  }

  .card__downloader {
    @include set-fa("019");

    @extend %primary-outline;

    border: 2px solid $clear;
    display: flex;
    justify-content: center;
    padding: beat(1) $el-pad;

    &::before {
      @extend %primary-solid;

      border-radius: 50%;
      display: inline-block;
      padding: 0 6px;
    }

    &:focus,
    &:hover {
      background-color: $white;
      color: $black;
    }

    &:focus {
      border-color: #21719c;
    }

    * {
      flex: 0 1 auto;
    }
  }
}

// Card Features
.features {
  .card__body {
    display: grid;
    gap: beat(1) $el-pad;
    grid-template-columns: 5rem 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: 'icon title' 'icon desc';
    padding: beat(4) $table-sides;

    .feature__title {
      font: 500 var(--base);
    }
  }
}

.feature__icon {
  grid-area: icon;
  width: 100%;

  figure {
    align-items: center;
    background-color: #f1f1f1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: .85rem .75rem;

    img {
      width: 100%;
      height: 50px;
    }
  }
}

