.alert {
  @extend %component;

  margin-bottom: beat(4);

  // max-width: $type-metre;

  * {
    margin-top: 0;
    margin-bottom: 0;
  }

  & > * + * {
    margin-top: beat(1);
  }

  &-info {
    @extend %info-outline;
  }

  &-warning {
    @extend %warning-outline;
  }

  &-danger {
    @extend %danger-outline;
  }

  &-success {
    @extend %success-outline;
  }
}

.alert-head {
  font: 700 var(--base);
  margin-top: 0 !important;
  padding: 0;

  &.lg {
    font: 500 var(--large-type);
  }
}

.alert {

  .state-icon,
  *[data-icon] {
    & ~ * {
      padding-left: calc(#{$icon-kern} + #{$icon-size});
    }

    & ~ *.alert-foot {
      padding-left: calc(#{$icon-kern} + #{$icon-size} + #{$el-pad});
    }

  }
}

.alert-foot {
  @include strap-stretch;

  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0 0 $border-radius-base $border-radius-base;
  margin-bottom: beat(-2);
  padding-top: beat(1);
  padding-bottom: beat(1);

  & > * {
    margin: 0 !important;
    padding: 0;
  }

  &.btn-holder {
    display: flex;
    gap: $grid-gutter-width;
  }
}

.alert-check {
  .radio input[type="radio"],
  .checkbox input[type="checkbox"] {
    position: relative;
    margin: 0;
  }
}