/**
 * CSS Compiled on: 2024-02-05 14:04:21 -08:00
 * ============================================================================== *
 */

// Import the Bootstrap core 
// (This file imports all Bootstrap core's variables and components from the Bootstrap core package installed via npm)
// @import '../../node_modules/normalize-scss/sass/normalize/normalize';
// @import '../../node_modules/normalize.css/normalize.css';

@import 'config/bootstrap-custom';
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    END BOOTSTRAP
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */
@import 'config/font-awesome-custom';
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    END FONT AWESOME
/* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */

@import 'config/config';
@import 'config/type-system';
@import 'config/lib-custom-properties';
@import 'config/lib-placeholder-class';

@import 'base-typography';

// Composition/Layouts
@import 'compositions/rows';
@import 'compositions/cols';
@import 'compositions/page';
@import 'compositions/galleries';
@import 'compositions/component';

// Utilities
@import 'utilities/graphics';
@import 'utilities/wrappers';
@import 'utilities/flow';
@import 'utilities/splitter';
@import 'utilities/horizontal';
@import 'utilities/iconography';

// // Blocks/Modules
@import 'blocks/bootstrap-overwrites';
@import 'blocks/global-nav';
@import 'blocks/header-public';
@import 'blocks/site-footer';
@import 'blocks/sidebar';
@import 'blocks/lists';
@import 'blocks/cards';
@import 'blocks/sections';
@import 'blocks/forms';
@import 'blocks/forms-buttons';
@import 'blocks/contact-form';
@import 'blocks/contact-display';
@import 'blocks/forms-process-map';
@import 'blocks/forms-acknowledgement';
@import 'blocks/forms-signature';
@import 'blocks/tables';
@import 'blocks/dividers';
@import 'blocks/alerts';
@import 'blocks/jumbotron';
@import 'blocks/toast';
@import 'blocks/collapsor';
@import 'blocks/page-panels';
@import 'blocks/tab_prehead';
@import 'blocks/beta-tab';
@import 'blocks/mod-dataTables';
@import 'blocks/video';
// ************************
@import 'blocks/dev-styles';

@import 'exceptions/states';

// @import 'custom_partials/print';

// Kill the printing of full URLs
@media only print {
  a[href]::after {
    content: '';
  }
}
