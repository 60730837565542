@mixin colorChips($cMap) {
  @each $color, $value in $cMap {
    $i: index($cMap, $color $value);
    .swatch:nth-child(#{$i}) {
      background-color: $value;
      color: set-text-color($value);

      &::before {
        position: absolute;
        content: '#{$color}';
        color: #000;
        bottom: -1.5em;
        font-size: 14px;
      }
    }
  }
}

.swatch-holder {
  display: flex;

  .swatch {
    border: 2px solid $clear;
    flex: 1;
    margin-left: 3px;
    min-height: 40px;
    position: relative;

    &:nth-child(11) {
      border: 2px solid $black;
    }
  }

  &.grayscale {
    @include colorChips($grayscale);
  }

  &.primary {
    @include colorChips($primary);
  }

  &.accent {
    @include colorChips($accent);
  }

  &.secondary {
    @include colorChips($secondary);
  }

  &.tertiary {
    @include colorChips($tertiary);
  }

  &.info {
    @include colorChips($info);
  }

  &.warning {
    @include colorChips($warning);
  }

  &.danger {
    @include colorChips($danger);
  }

  &.success {
    @include colorChips($success);
  }
}

.safe {
  color: var(--gray-safe);
}

.component {
  --gray-safe: #{darken($gray-safe, 1)};
}

.shaded {
  --gray-safe: #{darken($gray-safe, 7)};
}

.dark {
  --gray-safe: #{lighten($gray-safe, 49)};
}

.inverse {
  --gray-safe: #{lighten($gray-safe, 9)};
}

// .component-classic {
//   @extend %component;
//   border: 1px solid $comp-border;
//   background-color: $comp-bg;
// }
// .component-shaded-classic {
//   padding: beat(2) $el-pad;
//   border-radius: 6px;
//   // @extend %component;
//   @extend %component-shade-colors;
// }
// .component-new {
//   @extend %grayscale-outline;
//   @extend %component;
// }
// .component-shaded-new {
//   @extend %component;
//   @include comp-colors-shade('grayscale');
// }
// .component-shaded-dark {
//   @extend %component;
//   @extend %grayscale-solid;
// }
// .component-inverse {
//   // @extend %component;
//   padding: beat(2) $el-pad;
//   border-radius: 6px;
//   @extend %inverse-colors;
// }