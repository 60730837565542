.signature-content {
  background-color: $white;
  max-width: 600px;
  position: relative;
  z-index: 5;
  
  .subsection-head {
    margin-bottom: beat(4);
  }
}

.signature-container {

  & > div {
    border: 1px solid var(--comp-border-color);
    height: 150px;
    max-width: 100%;
    overflow-y: hidden;
  }

  img {
    height: 172px;
    margin-top: 3px;
    width: 590px;
  }

  // .form-group {
  //   background-color: var(--comp-background-color);
  //   background-color: pink;
  //   display: flex;
  //   margin: 0;
  //   padding: beat(2) $el-pad;

  //   .control-label {
  //     padding-right: $el-pad;
  //     white-space: nowrap;
  //   }

  //   & > div {
  //     flex: 1;
  //   }
  // }
}

.switcher {
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;

  .btn-group .btn {
    @extend %component;

    &:focus,
    &:hover {
      background-color: $white;
    }

    &:first-child {
      border-top-left-radius: $border-radius-base;
    }

    &:last-child {
      border-top-right-radius: $border-radius-base;
    }

    &.selected {
      background-color: $white;
      border-bottom-color: $white;
    }
  }
}

*[data-icon="type"]::before {
  content: '\f11c';
}

*[data-icon="draw"]::before {
  content: '\f040';
}

.type-field-holder {
  @extend %component;
  border-radius: 0;
  border-style: solid;
  border-top-width: 0;
  padding: beat(1) $el-pad;

  transform: translateY(-130px);
  transition: transform 400ms ease-out;

  &[data-kbd="true"] {
    transform: translateY(0);
  }

}

#placeholder {
  position: relative;

  .instruct {
    background-color: rgba(255, 255, 255, 0.85);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


#typeit {
  position: relative;
}



@include responsive(xs) {
  .signature-container img {
    width: 100%;
    height: auto;
  }
}


@include responsive(xxs) {
  .signature-container,
  .switcher {
    margin-inline: -$el-pad;
  }
}