/*  1. Configuration Variables: Origin, sets and feeds Bootstrap overwrites  */

// Basic brand configurations
$ml-blue-dark: hsl(201, 65%, 37%); // #21709b
$ml-blue-light:  hsl(211, 78%, 81%); // #a8cdf4
$ml-blue-lighter: lighten($ml-blue-light, 15);
$ml-teal: hsl(184, 100%, 26%); //#007d85

$gradBottom:  $ml-blue-light;
$gradTop:     lighten($gradBottom, 15);

// Adobe red - for PDFs
$adobe-red: hsl(1, 55%, 32%); // #AD0B01

// Shading
$black: #000;
$white: #fff;
$gray: mix($black, $white, $weight:50%);
$clear: transparent;
$gray-safe: #767676;
// AAA Compliant
// $gray-safe: #595959;

// Transparency Tints & Shadings
$tint05:              rgba(255, 255, 255, 0.05);
$tint10:              rgba(255, 255, 255, 0.1);
$tint17:              rgba(255, 255, 255, 0.175);
$tint25:              rgba(255, 255, 255, 0.25);
$tint50:              rgba(255, 255, 255, 0.5);
$tint87:              rgba(255, 255, 255, 0.875);
$shade05:             rgba(0, 0, 0, 0.05);
$shade10:             rgba(0, 0, 0, 0.1);
$shade17:             rgba(0, 0, 0, 0.175);
$shade25:             rgba(0, 0, 0, 0.25);
$shade50:             rgba(0, 0, 0, 0.5);
$shade87:             rgba(0, 0, 0, 0.875);

//== Bootstrap Colors
//
//## Gray and brand colors for use across Bootstrap.
$gray-base:           #000;
$gray-darker:         lighten($gray-base, 13.5%); // #222
$gray-dark:           lighten($gray-base, 20%); // #333
// $gray:                lighten($gray-base, 33.5%); // #555
$gray-light:          lighten($gray-base, 46.7%); // #777
$gray-lighter:        lighten($gray-base, 93.5%); // #eee

$brand-primary:       $ml-blue-dark;
$brand-info:          $brand-primary;
$brand-warning:       #b39939;
$brand-danger:        #982116;
$brand-success:       #5f9571;

// Custom color system variables
$info-base:          $brand-info;
$warning-base:       $brand-warning;
$danger-base:        $brand-danger;
$success-base:       $brand-success;

$link-color:          darken($brand-primary, 8);
$link-hover-color:    $brand-primary;


// Components
$comp-border:         #ddd;
$comp-bg:             #f5f5f5;
$comp-bg-shade:       darken($comp-bg, 30);
$comp-border-shade:   darken($comp-bg-shade, 5);

$hover-bgi: linear-gradient(to bottom, $tint10 0%, rgba(255, 255, 255, 0) 100%);

$font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-serif: Georgia, "Times New Roman", Times, serif;

// Typography variables
$line-height: 1.5;

// Desktop sizes
$base-font-size: 16px;
$base-line-height: $line-height;
$type-rhythm-px: $base-font-size * $line-height;
$type-metre: 60rem;

// Minor Third Scale: 1.2
// https://type-scale.com/?size=16&scale=1.200&text=Demo%20Type%20Scale&font=Helvetica%Neue&fontweight=400&bodyfont=Helvetica%Neue&bodyfontweight=400&lineheight=1.45&backgroundcolor=white&fontcolor=%23333&preview=false
$type-scale: 1.2;
$type-rhythm-px: $base-font-size * $line-height;

$nrw-font-size: 14px;
$nrw-line-height: $line-height;

// Major Second Scale: 1.125
// https://type-scale.com/?size=16&scale=1.125&text=Demo%20Type%20Scale&font=Helvetica%Neue&fontweight=400&bodyfont=Helvetica%Neue&bodyfontweight=400&lineheight=1.45&backgroundcolor=white&fontcolor=%23333&preview=false
$nrw-type-scale: 1.125;
$nrw-type-rhythm-px: $nrw-font-size * $line-height;

$print-font-size: 12px;
$print-line-height: $line-height;

// Major Second Scale: 1.125 - see above
$print-type-scale: 1.125;

// Set the Type Size names, assign placement on the scale
$type-map: (
  x-small-type: -2, 
  small-type: -1, 
  base: 0, 
  large-type: 1, 
  x-large-type: 2, 
  xx-large-type: 3, 
  primary-head: 4
);

$icon-size: 1rem;
$icon-kern: 0.35em;

$grid-gutter-width: 30px;
$el-pad: $grid-gutter-width / 2;
$table-sides: $el-pad / 2;

