.toast {
  // @extend %component;

  // border-radius: 0;
  // border-left-width: 1rem;
  background-color: $white !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border: 1px solid var(--comp-border-color);
  display: flex;
  gap: $el-pad;
  padding: beat(1) $el-pad beat(1) 0;
  transition: all 1s ease;

  &::before {
    content: '';
    background-color: var(--comp-key-color);
    width: 1rem;
    margin-bottom: beat(-1);
    margin-top: beat(-1);
  }


  &[data-init="true"] {
    opacity: 1;
    transform: translateY(-20px);
  }

  &[data-init="false"] {
    opacity: 0;
    transform: translateY(20px);
  }

}