.panel-trigger {
    @extend %component;

    @extend %primary-outline;

    font-weight: 500;
    text-align: left;
    width: 100%;
    border-top: 0;
    border-radius: 0;
    transition: background-color .25s linear;

    &:focus,
    &:hover {
        background-image: linear-gradient(to top, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 80%);
    }

    &:first-of-type {
        border-radius: $border-radius-base $border-radius-base 0 0;
        border-top: 1px solid var(--comp-border-color);
    }

    &:last-of-type {
        border-radius: 0 0 $border-radius-base $border-radius-base;
    }

    &[data-unavailable="true"] {
        --comp-background-color: var(--compshade-bg);
        --comp-border-color: var(--compshade-bg);
        background-image: none;
        color: var(--body-text);
    }

    &[aria-expanded="true"] {
        @extend %grayscale-solid;

        &:focus,
        &:hover {
            background-image: none;
        }

        span::before {
            transform: rotate(90deg);
        }
    }
}

.subsection-head+.panel-trigger {
    margin-top: beat(4);
}

.panel-trigger {
    span {
        @include set-fa("105");

        display: flex;

        &::before {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1em;
            width: 1em;
            border-radius: .5em;
            line-height: 1;
            font-size: 1.5em;
            transition: transform .25s ease;
        }
    }
}

.panel__header {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .sub-head {
        border-top: 1px solid var(--grayscale-light);
        color: var(--gray-safe);
        flex-basis: 100%;
        font-style: italic;
        margin-top: 0.375rem;
        order: 3;
        padding-left: 8px;
        padding-top: beat(1);
    }

    .btn {
        padding: beat(0.5) $el-pad;
    }
}

.panel__description {

    &>div {
        @extend %component;
        border-top: 0;
        border-radius: 0;
    }

    dt {
        font-weight: 700;
    }

    dd {
        padding-left: 1rem;

        ul {
            padding-left: 1em;
        }
    }

    dd+dt {
        margin-top: 1.5rem;
    }

    .alert {
        border: 2px solid var(--comp-key-color);
    }

}

@include responsive(md-up) {
  .panel__grid {
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: repeat(13, auto) 1fr;
    align-items: top;

    &::after {
      content: '';
      display: block;
      grid-column: 1 / 2;
      height: 50px;
    }

    .panel__description {
      grid-area: 1 / -1 / -2 / -1;
      opacity: 1;
      transition: opacity .25s linear;

      & > div {
        border-color: $clear;
        border-radius: $border-radius-base;
        padding-left: $grid-gutter-width;
      }

      &[aria-hidden="true"] {
        opacity: 0;
      }
    }

    .subsection-head,
    .panel-trigger {
      grid-column: 1 / 2;
    }

    .panel-trigger span {
      flex-direction: row-reverse;
      justify-content: space-between;

      &::before {
        margin-right: 0;
        transition: transform .25s ease;
      }
    }

    .panel-trigger[aria-expanded="true"] span::before {
      transform: rotate(0) translatex(5px) scale(1.2);
    }

    .subsection-head {
      align-items: flex-end;
      border-color: $clear;
      justify-content: center;
      padding-top: beat(3.5);
    }

    .subsection-head + .panel-trigger {
      margin-top: 0;
    }
  }
}


@include print-screen {
  .panel__grid {
    display: grid;
    grid-template-columns: 2.5in 1fr;
    grid-template-rows: repeat(13, auto) 1fr;
    align-items: top;

    &::after {
      content: '';
      display: block;
      grid-column: 1 / 2;
      height: 50px;
    }

    .panel__description {
      grid-area: 1 / -1 / -2 / -1;
      opacity: 1;
      transition: opacity .25s linear;

      & > div {
        border-color: $clear;
        padding-left: $grid-gutter-width;
      }

      &[aria-hidden="true"] {
        opacity: 0;
      }
    }

    .subsection-head,
    .panel-trigger {
      grid-column: 1 / 2;
    }

    .panel-trigger span {
      flex-direction: row-reverse;
      justify-content: space-between;

      &::before {
        margin-right: 0;
        transition: transform .25s ease;
      }
    }

    .panel-trigger[aria-expanded="true"] span::before {
      transform: rotate(0) translatex(5px) scale(1.2);
    }

    .subsection-head {
      align-items: flex-end;
      border-color: $clear;
      justify-content: center;
      padding-top: beat(3.5);
    }

    .subsection-head + .panel-trigger {
      margin-top: 0;
    }
  }
}