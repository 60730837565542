.acknowledgements {
  .item {
    @extend %component;

    border-top-width: 0;
    border-radius: 0;
    display: flex;
    gap: $el-pad;

    &:first-child {
      border-radius: $border-radius-large $border-radius-large 0 0;
      border-top-width: 1px;
    }

    &:last-child {
      border-radius: 0 0 $border-radius-large $border-radius-large;
    }

    &:has(input:checked) {
      background-color: $white;
      color: var(--body-text);
    }
  }

  label {
    cursor: pointer;
    flex: 1;
    font: 500 var(--large-type);
    padding-top: beat(0.5);
  }

  .cb-holder {
    flex-basis: 2rem;

    input[type="checkbox"] {
      cursor: pointer;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}