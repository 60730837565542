:root {
  --ten-col: calc(calc(#{$screen-lg} / 12) * 10 + #{$grid-gutter-width});
}

.columns {
  display: flex;
  flex-wrap: wrap;
  gap: beat(4) $grid-gutter-width;

  & > * {
    flex: 1;
    min-width: var(--min-col-width);
  }
}

@include responsive(xs-up) {
  .columns {
    --min-col-width: calc(767px / 2);
  
    &.reverse {
      flex-direction: row-reverse;
    }

    // 1--2
    &.layout-12 {
      & > *:first-child {
        min-width: $screen-lg / 3;
      }

      & > *:last-child {
        flex: 2;
      }
    }

    // 1--3
    &.layout-13 {
      & > *:first-child {
        min-width: $screen-lg / 4;
      }

      & > *:last-child {
        flex: 3;
      }
    }

    // 2--1
    &.layout-21 {
      & > *:first-child {
        flex: 2;
      }

      & > *:last-child {
        min-width: $screen-lg / 3;
      }
    }

    // 3--1
    &.layout-31 {
      & > *:first-child {
        flex: 3;
      }

      & > *:last-child {
        min-width: $screen-lg / 4;
      }
    }
  }
}

@include responsive(xxs){
  .columns {
    --min-col-width: 100%;

    & > * {
      min-width: var(--min-col-width) !important;
    }
  }
};

.single-col {
  display: flex;
  flex-direction: column;
}

.metre-width {
  max-width: $type-metre;
}

.half-width {
  max-width: $screen-lg / 2;
}

.third-width {
  max-width: $screen-lg / 3;
}

.qtr-width {
  max-width: $screen-lg / 4;
}

@include responsive(xs-up) {
  .two-col {
    display: flex;
    flex-wrap: wrap;
    margin: 0 ($el-pad * -1);

    & > * {
      flex: 1;
      min-width: 260px;
      padding: 15px;
    }

    &.auto-cols {
      display: inline-flex;

      & > * {
        flex: 1 1 auto;
      }
    }
  }

  .aside-layout {
    & > *:first-child {
      flex-grow: 2;
      min-width: 400px;
    }

    & > *:last-child {
      min-width: 300px;
    }

    &.sm {
      & > *:first-child {
        flex-grow: 3;
      }

      & > *:last-child {
        min-width: 200px;
      }
    }
  }

  .nav-layout {
    & > *:first-child {
      flex: 1;
    }

    & > *:last-child {
      flex: 3;
    }
  }
}


@include responsive(xs) {
  .aside-col {
    max-width: 400px;
    margin-inline: auto;
  }
}


@include print-screen {
  .two-col {
    display: flex;
    gap: 0.45in;

    & > * {
      flex: 1;
    }

    &.aside-layout > *:first-child {
      flex: 3;
    }
  }
}


// .feature {
//   @extend %vertical-expander;

//   margin-bottom: beat(4);

//   &__head {
//     @include underliner;

//     &[data-sub] {
//       border-color: $clear;
//       padding-bottom: 0;
//     }

//     .sub-head {
//       border-top: 1px solid var(--comp-border);
//       padding-left: $table-sides;
//       padding-right: $table-sides;
//     }
//   }

//   &__body {
//     padding: beat(2) 0;

//     * + * {
//       margin-top: beat(4);
//     }
//   }

//   &__foot {
//     padding-top: beat(2);
//   }
// }